lookup =
  'saver':
    '10 Year Policy without additional tax liability': { 16: 444, 17: 444, 18: 444, 19: 444, 20: 443, 21: 443, 22: 443, 23: 443, 24: 443, 25: 443, 26: 443, 27: 443, 28: 443, 29: 443, 30: 443, 31: 443, 32: 443, 33: 443, 34: 443, 35: 443, 36: 443, 37: 443, 38: 443, 39: 443, 40: 442, 41: 442, 42: 442, 43: 442, 44: 441, 45: 441, 46: 441, 47: 440, 48: 440, 49: 439, 50: 439, 51: 438, 52: 437, 53: 437, 54: 436, 55: 435, 56: 433, 57: 432, 58: 431, 59: 429, 60: 427 }
    '15 Year Policy without additional tax liability': { 16: 708, 17: 708, 18: 708, 19: 708, 20: 708, 21: 708, 22: 708, 23: 708, 24: 708, 25: 708, 26: 708, 27: 708, 28: 707, 29: 707, 30: 707, 31: 707, 32: 707, 33: 707, 34: 706, 35: 706, 36: 706, 37: 705, 38: 705, 39: 704, 40: 703, 41: 703, 42: 702, 43: 701, 44: 700, 45: 699, 46: 697, 47: 696, 48: 694, 49: 692, 50: 690 }
    '20 Year Policy without additional tax liability': { 16: 1003, 17: 1002, 18: 1002, 19: 1002, 20: 1002, 21: 1002, 22: 1002, 23: 1001, 24: 1001, 25: 1001, 26: 1001, 27: 1000, 28: 1000, 29: 999, 30: 999, 31: 998, 32: 998, 33: 997, 34: 996, 35: 995, 36: 994, 37: 992, 38: 991, 39: 989, 40: 987, 41: 985, 42: 982, 43: 979, 44: 976, 45: 972 }
    '25 Year Policy without additional tax liability': { 16: 1329, 17: 1328, 18: 1328, 19: 1327, 20: 1327, 21: 1327, 22: 1326, 23: 1326, 24: 1325, 25: 1324, 26: 1323, 27: 1322, 28: 1321, 29: 1320, 30: 1318, 31: 1317, 32: 1315, 33: 1313, 34: 1310, 35: 1307, 36: 1304, 37: 1300, 38: 1296, 39: 1292, 40: 1286 }
    '30 Year Policy without additional tax liability': { 16: 1687, 17: 1685, 18: 1685, 19: 1684, 20: 1683, 21: 1682, 22: 1680, 23: 1679, 24: 1677, 25: 1675, 26: 1673, 27: 1671, 28: 1668, 29: 1664, 30: 1661, 31: 1657, 32: 1652, 33: 1646, 34: 1640, 35: 1633 }
    '35 Year Policy without additional tax liability': { 16: 2072, 17: 2068, 18: 2066, 19: 2064, 20: 2062, 21: 2059, 22: 2056, 23: 2053, 24: 2049, 25: 2045, 26: 2039, 27: 2034, 28: 2027, 29: 2020, 30: 2011 }
    'Age 60 without additional tax liability':   { 16: 2704, 17: 2616, 18: 2535, 19: 2453, 20: 2372, 21: 2290, 22: 2209, 23: 2129, 24: 2049, 25: 1971, 26: 1893, 27: 1816, 28: 1740, 29: 1664, 30: 1592, 31: 1521, 32: 1450, 33: 1379, 34: 1310, 35: 1245, 36: 1180, 37: 1115, 38: 1052, 39: 989, 40: 930, 41: 872, 42: 814, 43: 757, 44: 700, 45: 647, 46: 595, 47: 543, 48: 491, 49: 439 }
    'Age 65 without additional tax liability':   { 16: 3069, 17: 2983, 18: 2904, 19: 2825, 20: 2744, 21: 2663, 22: 2582, 23: 2501, 24: 2419, 25: 2338, 26: 2258, 27: 2178, 28: 2098, 29: 2020, 30: 1941, 31: 1864, 32: 1788, 33: 1714, 34: 1640, 35: 1568, 36: 1497, 37: 1427, 38: 1359, 39: 1292, 40: 1227, 41: 1162, 42: 1099, 43: 1037, 44: 976, 45: 918, 46: 859, 47: 802, 48: 747, 49: 692, 50: 640, 51: 587, 52: 536, 53: 485, 54: 436 }
  'saver-disability':
    '10 Year Policy without additional tax liability': { 16: 439, 17: 439, 18: 439, 19: 439, 20: 439, 21: 439, 22: 439, 23: 439, 24: 439, 25: 439, 26: 439, 27: 439, 28: 439, 29: 439, 30: 439, 31: 439, 32: 439, 33: 439, 34: 438, 35: 438, 36: 438, 37: 438, 38: 438, 39: 437, 40: 437, 41: 436, 42: 436, 43: 435, 44: 434, 45: 433, 46: 431, 47: 429, 48: 427, 49: 425, 50: 423, 51: 420, 52: 418, 53: 415, 54: 412 }
    '15 Year Policy without additional tax liability': { 16: 694, 17: 694, 18: 693, 19: 693, 20: 693, 21: 693, 22: 693, 23: 693, 24: 693, 25: 693, 26: 693, 27: 693, 28: 693, 29: 693, 30: 692, 31: 692, 32: 692, 33: 692, 34: 691, 35: 690, 36: 690, 37: 689, 38: 687, 39: 686, 40: 684, 41: 681, 42: 678, 43: 675, 44: 671, 45: 666, 46: 661, 47: 655, 48: 649, 49: 642, 50: 636, }
    '20 Year Policy without additional tax liability': { 16: 965, 17: 965, 18: 965, 19: 965, 20: 965, 21: 964, 22: 964, 23: 964, 24: 964, 25: 964, 26: 963, 27: 963, 28: 962, 29: 962, 30: 961, 31: 960, 32: 959, 33: 957, 34: 955, 35: 953, 36: 950, 37: 946, 38: 942, 39: 937, 40: 931, 41: 923, 42: 915, 43: 906, 44: 896, 45: 886 }
    '25 Year Policy without additional tax liability': { 16: 1247, 17: 1246, 18: 1246, 19: 1246, 20: 1245, 21: 1245, 22: 1244, 23: 1244, 24: 1243, 25: 1242, 26: 1241, 27: 1239, 28: 1238, 29: 1235, 30: 1233, 31: 1229, 32: 1225, 33: 1221, 34: 1215, 35: 1208, 36: 1201, 37: 1192, 38: 1181, 39: 1169, 40: 1156 }
    '30 Year Policy without additional tax liability': { 16: 1525, 17: 1524, 18: 1523, 19: 1522, 20: 1521, 21: 1520, 22: 1519, 23: 1517, 24: 1514, 25: 1512, 26: 1508, 27: 1504, 28: 1500, 29: 1494, 30: 1487, 31: 1479, 32: 1470, 33: 1459, 34: 1447, 35: 1434 }
    '35 Year Policy without additional tax liability': { 16: 1783, 17: 1779, 18: 1778, 19: 1776, 20: 1773, 21: 1770, 22: 1766, 23: 1762, 24: 1756, 25: 1750, 26: 1743, 27: 1734, 28: 1724, 29: 1713, 30: 1700 }
    'Age 60 without additional tax liability':   { 16: 2094, 17: 2056, 18: 2019, 19: 1979, 20: 1938, 21: 1895, 22: 1851, 23: 1804, 24: 1756, 25: 1702, 26: 1649, 27: 1596, 28: 1544, 29: 1494, 30: 1436, 31: 1379, 32: 1323, 33: 1268, 34: 1215, 35: 1157, 36: 1100, 37: 1044, 38: 990, 39: 937, 40: 881, 41: 826, 42: 773, 43: 721, 44: 671, 45: 620, 46: 569, 47: 520, 48: 472, 49: 425 }
    'Age 65 without additional tax liability':   { 16: 2225, 17: 2195, 18: 2165, 19: 2133, 20: 2099, 21: 2063, 22: 2025, 23: 1985, 24: 1943, 25: 1900, 26: 1855, 27: 1809, 28: 1762, 29: 1713, 30: 1658, 31: 1607, 32: 1554, 33: 1501, 34: 1447, 35: 1389, 36: 1334, 37: 1279, 38: 1224, 39: 1169, 40: 1111, 41: 1056, 42: 1002, 43: 949, 44: 896, 45: 842, 46: 791, 47: 740, 48: 691, 49: 642, 50: 593, 51: 547, 52: 501, 53: 456, 54: 412 }
  'childrens-saver':
    '10 Year Term':       { 6: 444, 7: 444, 8: 444, 9: 444, 10: 444, 11: 444, 12: 444, 13: 444, 14: 444, 15: 444 }
    'Maturity at Age 16': { 0: 761, 1: 710, 2: 655, 3: 600, 4: 547, 5: 495 }
    'Maturity at Age 18': { 0: 876, 1: 825, 2: 767, 3: 710, 4: 655, 5: 601, 6: 547, 7: 495 }
    'Maturity at Age 21': { 0: 1057, 1: 1007, 2: 946, 3: 885, 4: 826, 5: 767, 6: 710, 7: 655, 8: 600, 9: 547, 10: 495 }
    'Maturity at Age 25': { 0: 1316, 1: 1271, 2: 1203, 3: 1137, 4: 1072, 5: 1008, 6: 946, 7: 885, 8: 825, 9: 767, 10: 710, 11: 654, 12: 600, 13: 547, 14: 495 }

do ($ = jQuery) ->

  $ ->

    ###
    obs = new IntersectionObserver (e) ->
      e.forEach (el) ->
        ((($ el.target).css 'transition', 'opacity '+(Math.random()*2)+'s').addClass 'dun-animatin') if !(($ el.target).hasClass 'dun-animatin') && el.isIntersecting
    , {}
    (document.querySelectorAll 'div, li, input').forEach (el) -> obs.observe el
    ###

    ($ document.forms).each ->
      form = this
      ol   = (($ this).find '> ol')[0]
      min  = 3
      max  = 100
      ($ form).attr
        'autocomplete': 'off'
      (($ form).find('input[name="weekly_premium"]').attr
        'type': 'number'
        'min': min
        'max': max
        'step': '0.01'
      ).on 'blur', ->
        x = parseFloat ($ this).val()
        ($ this).val Math.max(Math.min(max, x), min)
        display_sums()

      posty_index = 0;
      (($ form).find '[name*="postcode"]').each ->
        # console.log "Postcode found"
        $posty   = ($ this)
        $xs      = (($posty.parents 'form').find 'input')
        $address = $xs.eq ($xs.index $posty) + 1
        if $posty.length && $address.length
          # console.log $posty
          $address.attr 'autocomplete', 'no'
          $address.focus ->
            # console.log $posty
            ($.ajax
              url: '/wp-json/enginemens/v1/postcode'
              method: 'POST'
              data: {'posty': $posty.val()}
            ).done (data) ->
              addresses = ((((x.split ', ').filter (x) -> x.length).join ', ') for x in (JSON.parse data).addresses)
              $datalist = ($ '<datalist/>').attr 'id', 'steve'+posty_index
              ++posty_index
              $datalist.append ($ '<option>').val address for address in addresses
              $address.after $datalist
              $address.attr 'list', $datalist.attr 'id'

      (($ form).find '[type="submit"]').click ->
        double_or_nothing = (x for x in form.elements when (($ x).hasClass 'double') && (($ x).prop 'checked')).length > 0
        if double_or_nothing
          form.elements['weekly_premium'].value = 2*form.elements['weekly_premium'].value

      if ol?

        plan = ($ ol).data 'plan'

        saved_application = localStorage.getItem plan
        ($ form).first().deserialize saved_application if saved_application?.length

        ($ form).change -> localStorage.setItem plan, ($ this).first().serialize()

        (($ ol).find 'li:nth-child(1) .button').click ->
          slurped = localStorage.getItem "#{plan}-slurped"
          if slurped != 'yes'
            payload = {}
            payload['form_id'] = ($ form.elements['_wpcf7']).val()
            for input in ($ ol).find 'li:first-child input'
              switch input.type
                when 'radio', 'checkbox'
                  payload[input.name] = ($ input).val() if ($ input).prop 'checked'
                else
                  payload[input.name] = ($ input).val()
            ($.ajax
              url: '/wp-json/enginemens/v1/slurp'
              method: 'POST'
              data: payload
            ).done (data) ->
              localStorage.setItem "#{plan}-slurped", data

        size_page = -> ($ ol).css 'height', (($ ol).find 'li.active').outerHeight true
        ($ window).resize -> size_page()

        go_to_page = (page_idx) ->
          ((((($ ol).find 'li').css 'transform', 'translateX(-'+(page_idx*100)+'%)').removeClass 'active').eq page_idx).addClass 'active'
          size_page()

        # navigation and input validation
        # inputs must validate to proceed to the next screen in the application
        ($ ol).on 'click', '[data-action="1"]', ->
          #console.log "data-action click"
          validated = true

          # check if a policy is selected
          policy_radios_field = $(this).parent('li').find('.policy_term')
          if policy_radios_field.length
            #console.log "policy_radios_field: ", policy_radios_field.length
            term_has_been_selected = policy_radios_field.find('[type="radio"]:checked').length == 1
            #console.log "term_has_been_selected: ", term_has_been_selected
            validated = term_has_been_selected;

          for input in (($ this).parent 'li').find '[aria-required="true"]'
            #console.log input
            valid = true # NOTE(rogb): innocent until proven guilty

            # simple validation for email
            switch input.type
              when 'email'
                valid = ($ input).val().includes '@'
              else
                valid = ($ input).val().length > 0

            # simple validation for DOB
            if ($ input)[0].name == 'dob'
              #console.log 'input is DOB'
              dob_valid = ($ input).val().includes '/'
              if !dob_valid
                valid = false

            # change appearance if invalid
            if !valid
              (($ input).addClass 'wpcf7-not-valid').attr 'aria-invalid', 'true'
              validated = false
            else
              (($ input).removeClass 'wpcf7-not-valid').attr 'aria-invalid', 'false'

          #console.log "Validation state: ", validated
          #console.log "Data action: ", $(this).data('action')

          if validated
            #console.log "Action: Valid"
            go_to_page (($ this).parent 'li').index() + ($ this).data 'action'

        ($ ol).on 'click', '[data-action="-1"]', ->
          # console.log "Action: Going backwards"
          go_to_page (($ this).parent 'li').index() + ($ this).data 'action'

        ($ form).parent().on 'wpcf7invalid', ->
          $first_invalid_element = (($ ol).find '.wpcf7-not-valid').first()
          $first_invalid_page    = ($first_invalid_element.parents 'li').first()
          go_to_page $first_invalid_page.index()

        ($ form).parent().on 'wpcf7mailsent', -> localStorage.removeItem plan

        # calculation of sums
        nope = "£ ──"

        $term_els = ($ ol).find '.policy_term > span > span'
        $term_els.first().before '<span><span></span><span>Based on your premium of <i id="pricey">£-</i></span><span>Double your premium</span></span>'
        $term_els.each ->
          $a   = ($ this).find '> label'
          term = ($a.find 'span').text()
          $b   = $a.clone().insertAfter $a

          ($a.find 'span').text nope
          ($b.find 'span').text nope

          ($b.find 'input').addClass 'double'

          $a.before '<span>'+term+'</span>'

        display_sums = ->
          dob = (($ ol).find (if plan == 'childrens-saver' then '[name="child_dob"]' else '[name="dob"]')).val()
          # console.log "dob: ", dob,

          if dob?
            [dd, mm, yy] = dob.split '/'
            # console.log "yy, mm, dd: ", yy, mm, dd

            # age_calc_yy = (parseInt yy)
            # age_calc_mm = (parseInt mm)-1
            # age_calc_dd = (parseInt dd)
            # console.log "age_calc_yy: ", age_calc_yy, " | age_calc_mm: ", age_calc_mm, " | age_calc_dd: ", age_calc_dd

            age_calc = new Date (parseInt yy), (parseInt mm)-1, (parseInt dd)
            # console.log "age_calc: ", age_calc

            age = Math.floor (new Date() - age_calc)/1000/60/60/24/365
            # console.log "age: ", age

            ppm = (($ ol).find '[name="weekly_premium"]').val()
            # console.log "ppm: ", ppm

            # maximum and minimum ages for policies
            max_age = 0
            min_age = 1000
            for plan_key, plan_value of lookup[plan]
              for age_key, age_value of plan_value
                plan_age = parseInt(age_key)
                if plan_age > max_age
                  max_age = plan_age
                if plan_age < min_age
                  min_age = plan_age

            age_error = $('.age-error')

            $('#step-investment-choice .age-error').remove()

            if isNaN(age) && !age_error.length
              # console.log 'Age is NaN: ', age_is_nan.length
              $('#step-investment-choice > div.field').after('<div class="age-error wpcf7-validation-errors">The date of birth that you have entered is not valid, and cannot be used. Please use the back button to review for errors. </div>')
            if age > max_age or age < min_age && !age_error.length
              $('#step-investment-choice > div.field').after('<div class="age-error wpcf7-validation-errors">The date of birth that you have entered is not within the accepted range for our products, and cannot be used. Please use the back button to review for errors. </div>')

            $term_els.each ->
              $els    = ($ this).find '> label > span'
              $radios = ($els.siblings '[type="radio"]')
              term    = $radios.first().val()
              amount  = lookup[plan]?[term]?[age]

              # debug
              # console.log "term: ", term, " :: amount: ", amount

              if amount?
                ($els.first().parent().siblings 'span').removeClass 'disabled'
                $radios.prop 'enabled', true
                $radios.removeClass 'disabled'
                amount_times_ppm = (amount*ppm).toFixed(2)
                amount_times_ppm_doubled = (amount*ppm*2).toFixed(2)
                ($els.eq 0).text '£'+amount_times_ppm
                ($els.eq 1).text '£'+amount_times_ppm_doubled
                ($ '#pricey').text '£'+(parseFloat(ppm).toFixed(2))

              else
                $els.text nope
                ($els.first().parent().siblings 'span').addClass 'disabled'
                $radios.addClass 'disabled'
                $radios.prop 'checked', false
                $radios.prop 'enabled', false
                ($ '#pricey').text '£'+ppm
            size_page()
        display_sums()

        # NOTE(suzi): Now we've load the sums, need to fix up stored data
        (($ form).find '[name="policy_term"]').each -> ($ this).prop 'checked', ((($ this).next 'span').text() == ($ '[name="sum_assured"]').val())

        update_sum_assured = ->
          # number = ($form-item).clicked.sibling($ 'wpcf7-list-item-label').text
          number = ($ form).find('[name="policy_term"]:checked + span').text()
          ($ '[name="sum_assured"]').val(number)

        ($ form).on 'input', '[name="policy_term"], [name="weekly_premium"], [name*="dob"]', ->
          display_sums()
          update_sum_assured()

        ($ form).on 'change', '[name="policy_term"], [name="weekly_premium"], [name*="dob"]', ->
          display_sums()
          update_sum_assured()

        ($ '[name*="_dob"]').datepicker
          defaultDate: "-16y"
          dateFormat:  'dd/mm/yy'
          changeMonth: true
          changeYear:  true
          yearRange:   '-70:+0'

        ($ '[name="dob"]').datepicker
          defaultDate: "-16y"
          dateFormat:  'dd/mm/yy'
          changeMonth: true
          changeYear:  true
          yearRange:   '-70:-16'

